<template>
  <div class="header_box">
    <div class="head_cont  m_auto flex">
      <div class="logo_menu_icon flex">
        <p class="logo">
          <img src="@/assets/pc/index/logo.png" alt="">
        </p>
        <p class="menu_icon">
          <img @click="menuState=!menuState" v-if="!menuState" src="@/assets/m/menu_icon.png" alt="">
          <img @click="menuState=!menuState" v-else src="@/assets/m/menu_close.png" alt="">
        </p>
      </div>
      <ul class=" titleMenu" v-if="menuState">
        <li>
            <router-link active-class="activeMenu" to="/index">首页</router-link>
        </li>
        
        <li>
            <router-link active-class="activeMenu" to="/cooperate">业务案例</router-link>
        </li>
        
       <li>
            <router-link active-class="activeMenu" to="/action">新闻中心</router-link>
        </li>
        <!-- 
        
          <li>
            <router-link active-class="activeMenu" to="/index6">客户服务</router-link>
        </li> -->
        <li>
            <router-link active-class="activeMenu" to="/aboutUs">关于我们</router-link>
        </li>
        
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "headers",
  data() {
    return {
        menuState:false
    };
  },
  watch: {
    $route: {
      handler(newVal) {
       this.menuState = false
      },
      immediate: true,
    },
  },
  props: {
    menu: Array,
    logo: {
      type: String,
      default: "",
    },
  },
  components: {
  },
  mounted() {
    
  },
  methods: {
    
    
  },
};
</script>

<style scoped lang="scss">
.header_box{
  height: 132px;
  background: rgba(51,51,51,.3);
  position: relative;
  .head_cont{
    .logo_menu_icon{
      box-sizing: border-box;
      padding: 0 20px 0 32px;
      justify-content: space-between;
      width: 100%;
      height: 132px;
      p.logo{
        img{
          height: 58px;
          width: auto;
        }
      }
      p.menu_icon{
        img{
          width: 70px;
        }
      }
    }
    .titleMenu{
      position: absolute;
      left: 0;
      top: 132px;
      z-index: 999;
      background: rgba(49, 108, 255, .6);
      width: 100%;
      box-sizing: border-box;
      padding: 0 28px;
      li{
        border-bottom: 1px solid #fff;
        padding-bottom: 44px;
        margin-top: 46px;
        a{
          margin-left: 44px;
          color: #fff;
          font-size: 32px;
        }
      }
      li.activeMenu{
        a{
          font-weight: bold;
        }
      }
      li:last-child{
        border-bottom: none;
      }
    }
  }
}
</style>
