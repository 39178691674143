<template>
  <div class="header_box">
    <div class="head_cont w_1200 m_auto flex">
      <p class="logo">
        <img src="@/assets/pc/index/logo.png" alt="">
      </p>
      <ul class="flex titleMenu">
        <li>
            <router-link active-class="activeMenu" to="/index">首页</router-link>
        </li>
        <!-- <li class="hoverCont">
            <router-link active-class="activeMenu" to="/product">产品服务</router-link>
            <div class="menu_cont">
              <ul>
                <p class="tips">职业技能培训</p>
                <li>
                  <img src="@/assets/pc/menu/1.png" alt="">
                  <span>中工云课堂</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/2.png" alt="">
                  <span>学习平台建设</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/3.png" alt="">
                  <span>工匠学院建设</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/4.png" alt="">
                  <span>线上专题培训班</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/5.png" alt="">
                  <span>企业大学建设</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/6.png" alt="">
                  <span>大师来了</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/7.png" alt="">
                  <span>企业研学</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/8.png" alt="">
                  <span>渠道机构合作模式</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/9.png" alt="">
                  <span>院校线上学习平台专题建设</span>
                </li>
              </ul>
              <ul class="sec_box">
                <p class="tips">智慧工会建设</p>
                <div class="flex">
                  <div>
                  <li>
                  <img src="@/assets/pc/menu/1.png" alt="">
                  <span>省市级学习平台</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/2.png" alt="">
                  <span>工匠学院建设</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/3.png" alt="">
                  <span>工匠学院建设</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/4.png" alt="">
                  <span>线上专题培训班</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/5.png" alt="">
                  <span>企业大学建设</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/6.png" alt="">
                  <span>大师来了</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/7.png" alt="">
                  <span>企业研学</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/8.png" alt="">
                  <span>渠道机构合作模式</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/9.png" alt="">
                  <span>院校线上学习平台专题建设</span>
                </li>
                
                </div>
                <div>
                  <li>
                  <img src="@/assets/pc/menu/1.png" alt="">
                  <span>中工云课堂</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/2.png" alt="">
                  <span>学习平台建设</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/3.png" alt="">
                  <span>工匠学院建设</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/4.png" alt="">
                  <span>线上专题培训班</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/5.png" alt="">
                  <span>企业大学建设</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/6.png" alt="">
                  <span>大师来了</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/7.png" alt="">
                  <span>企业研学</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/8.png" alt="">
                  <span>渠道机构合作模式</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/9.png" alt="">
                  <span>院校线上学习平台专题建设</span>
                </li>
                </div>
                </div>
              </ul>
              <ul>
                <p class="tips">职业技能培训</p>
                <li>
                  <img src="@/assets/pc/menu/1.png" alt="">
                  <span>中工云课堂</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/2.png" alt="">
                  <span>学习平台建设</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/3.png" alt="">
                  <span>工匠学院建设</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/4.png" alt="">
                  <span>线上专题培训班</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/5.png" alt="">
                  <span>企业大学建设</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/6.png" alt="">
                  <span>大师来了</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/7.png" alt="">
                  <span>企业研学</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/8.png" alt="">
                  <span>渠道机构合作模式</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/9.png" alt="">
                  <span>院校线上学习平台专题建设</span>
                </li>
              </ul>
            </div>
        </li>
        <li class="hoverCont">
            <router-link active-class="activeMenu" to="/solution">解决方案</router-link>
            <div class="menu_cont">
              <ul>
                <p class="tips">职业技能培训</p>
                <li>
                  <img src="@/assets/pc/menu/1.png" alt="">
                  <span>中工云课堂</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/2.png" alt="">
                  <span>学习平台建设</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/3.png" alt="">
                  <span>工匠学院建设</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/4.png" alt="">
                  <span>线上专题培训班</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/5.png" alt="">
                  <span>企业大学建设</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/6.png" alt="">
                  <span>大师来了</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/7.png" alt="">
                  <span>企业研学</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/8.png" alt="">
                  <span>渠道机构合作模式</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/9.png" alt="">
                  <span>院校线上学习平台专题建设</span>
                </li>
              </ul>
              <ul class="sec_box">
                <p class="tips">智慧工会建设</p>
                <div class="flex">
                  <div>
                  <li>
                  <img src="@/assets/pc/menu/1.png" alt="">
                  <span>省市级学习平台</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/2.png" alt="">
                  <span>工匠学院建设</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/3.png" alt="">
                  <span>工匠学院建设</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/4.png" alt="">
                  <span>线上专题培训班</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/5.png" alt="">
                  <span>企业大学建设</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/6.png" alt="">
                  <span>大师来了</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/7.png" alt="">
                  <span>企业研学</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/8.png" alt="">
                  <span>渠道机构合作模式</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/9.png" alt="">
                  <span>院校线上学习平台专题建设</span>
                </li>
                
                </div>
                <div>
                  <li>
                  <img src="@/assets/pc/menu/1.png" alt="">
                  <span>中工云课堂</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/2.png" alt="">
                  <span>学习平台建设</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/3.png" alt="">
                  <span>工匠学院建设</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/4.png" alt="">
                  <span>线上专题培训班</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/5.png" alt="">
                  <span>企业大学建设</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/6.png" alt="">
                  <span>大师来了</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/7.png" alt="">
                  <span>企业研学</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/8.png" alt="">
                  <span>渠道机构合作模式</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/9.png" alt="">
                  <span>院校线上学习平台专题建设</span>
                </li>
                </div>
                </div>
              </ul>
              <ul>
                <p class="tips">职业技能培训</p>
                <li>
                  <img src="@/assets/pc/menu/1.png" alt="">
                  <span>中工云课堂</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/2.png" alt="">
                  <span>学习平台建设</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/3.png" alt="">
                  <span>工匠学院建设</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/4.png" alt="">
                  <span>线上专题培训班</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/5.png" alt="">
                  <span>企业大学建设</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/6.png" alt="">
                  <span>大师来了</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/7.png" alt="">
                  <span>企业研学</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/8.png" alt="">
                  <span>渠道机构合作模式</span>
                </li>
                <li>
                  <img src="@/assets/pc/menu/9.png" alt="">
                  <span>院校线上学习平台专题建设</span>
                </li>
              </ul>
            </div>
        </li> -->
        <li>
            <router-link active-class="activeMenu" to="/cooperate">业务案例</router-link>
        </li>
        
       <li>
            <router-link active-class="activeMenu" to="/action">新闻中心</router-link>
        </li>
        <!-- 
        
          <li>
            <router-link active-class="activeMenu" to="/index6">客户服务</router-link>
        </li> -->
        <li>
            <router-link active-class="activeMenu" to="/aboutUs">关于我们</router-link>
        </li>
        
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "headers",
  data() {
    return {
      
    };
  },
  props: {
    menu: Array,
    logo: {
      type: String,
      default: "",
    },
  },
  components: {
  },
  mounted() {
    
  },
  methods: {
    
    
  },
};
</script>

<style scoped lang="scss">
.header_box{
  border-bottom: 1px solid rgba(255,255,255,.33);
  height: 80px;
  //  background: #999;
  // position: fixed;
  // left: 0;
  // top: 0;
  width: 100%;
  z-index: 99;
  position: relative;
  z-index: 91;
  // background: #333;
  
  .head_cont{
    height: 100%;
     p.logo{
        height: 44px;
        img{
          width: auto;
          height: 44px;
        }
      }
    ul.titleMenu{
      margin-left: 175px;
      flex: 1;
      justify-content: space-between;
      align-items: center;
     
      >li{
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        height: 80px;
        color: #FFFFFF;
        position: relative;

        line-height: 80px;
        a{
          color: #fff;
        }
        .activeMenu {
          
        }
        .activeMenu::after {
          content: "";
          width: 30px;
          height: 2px;
          display: block;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 0px;
          background-color: #fff;
          border-radius: 30px;
        }
      }
      .hoverCont:hover{
        .menu_cont{
          display: flex;
        }
      }
      .menu_cont{
        position: absolute;
        left: -300px;
        top: 80px;
        width: 1021px;
        min-height: 540px;
        background: #FFFFFF;
        box-shadow: 0px 9px 7px 1px rgba(34,59,127,0.15);
        box-sizing: border-box;
        padding: 24px 39px 39px 35px;
        display: flex;
        justify-content: space-between;
        display: none;
        ul{
          p.tips{
            border-bottom: 1px solid  #DEE6EA;
            font-size: 15px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #2A2A2A;
            padding-bottom: 11px;
            margin-bottom: 24px;
            line-height: 20px;
          }
          li{
            margin-bottom: 25px;
            line-height: 30px;
            cursor: pointer;
            span{
              font-size: 13px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #2A2A2A;
              margin-left: 15px;
            }
          }
          li:hover{
            span{
              color: #316CFF;
            }
          }
          // div{
          //   display: flex;
          //   justify-content: space-between;
          //   flex-wrap: wrap;
          // }
        }
        ul.sec_box{
          width: 501px;
          >div{
            display: flex;
            justify-content: space-between;

          }
          // li{
          //   width: 50%;
          // }
        }
      }

    }
  }
}
</style>
