<template>
  <div class="foot_main">
    <div class="footer_box">
      <div class="footer_cont m_auto">
        <div class="left_about_us">
          <p class="logo"><img src="@/assets/pc/footer/logo.png" alt="" /></p>
          <div class="phone_cont">
            <div class="phon_text flex">
              <p class="tips">联系我们</p>
              <div class="phone_num flex">
                <img src="@/assets/pc/footer/phone.png" alt="" />
                <span>400-0123-823</span>
              </div>
            </div>
            <div class="flex adr_text">
              <p class="tips">地址</p>
              <p class="text">北京市东城区首东国际大厦B座4号楼17层</p>
            </div>
          </div>
        </div>
        <div class="ul_cont">
          <ul>
            <p>推荐产品</p>
            <div>
              <li>
                <a
                  href="http://open.kskstudy.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  >中工KSK开放平台</a
                >
              </li>
              <li>
                <a
                  href="https://npx.kskstudy.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  >中工云课堂</a
                >
              </li>
              <li>
                <a
                  href="https://zgyxk.kskstudy.com/index"
                  target="_blank"
                  rel="noopener noreferrer"
                  >中工云选课</a
                >
              </li>
              <li>
                <a
                  href="https://npx.kskstudy.com/teacherList"
                  target="_blank"
                  rel="noopener noreferrer"
                  >大师来了</a
                >
              </li>
              <li>
                <a
                  href="https://zgyxk.kskstudy.com/researchList"
                  target="_blank"
                  rel="noopener noreferrer"
                  >企业研学</a
                >
              </li>
            </div>
          </ul>
          <ul>
            <p>关于中工</p>
            <div>
              <li>
                <a
                  href="https://www.kjcxchina.com/aboutUs"
                  target="_blank"
                  rel="noopener noreferrer"
                  >公司简介</a
                >
              </li>
              <li @click="$router.push('/action')">
                <a href="javascript:;" rel="noopener noreferrer">新闻中心</a>
              </li>
              <!-- <li><a href="http://open.kskstudy.com/Join" target="_blank" rel="noopener noreferrer">人才招聘</a></li> -->
              <li @click="$router.push('/aboutUs')">
                <a href="javascript:;" rel="noopener noreferrer">人才招聘</a>
              </li>
              <li>
                <a
                  href=" http://open.kskstudy.com/History"
                  target="_blank"
                  rel="noopener noreferrer"
                  >发展历程</a
                >
              </li>
              <li>
                <a href="javascript:;" rel="noopener noreferrer">社会责任</a>
              </li>
            </div>
          </ul>
          <ul>
            <p>服务支持</p>
            <div>
              <li>
                <a href="javascript:;" rel="noopener noreferrer">政企服务</a>
              </li>
              <li>
                <a href="javascript:;" rel="noopener noreferrer">合作伙伴</a>
              </li>
              <!-- <li @click="aboutUsBtn()"><a href="javascript:;"  rel="noopener noreferrer">联系我们</a></li> -->
              <li @click="$router.push('/aboutUs')">
                <a href="javascript:;" rel="noopener noreferrer">联系我们</a>
              </li>
            </div>
          </ul>
          <!-- <ul>
            <p>友情链接</p>
            <div>
              <li><a href="http://www.mohrss.gov.cn/" target="_blank" rel="noopener noreferrer">中华人民共和国人力资源和社会保障部</a> </li>
              <li><a href="https://www.miit.gov.cn/" target="_blank" rel="noopener noreferrer">中华人民共和国工业和信息化部</a></li>
              <li><a href="https://www.acftu.org/" target="_blank" rel="noopener noreferrer">中华全国总工会</a></li>
              <li><a href="http://www.hbzgh.org.cn/" target="_blank" rel="noopener noreferrer">湖北省总工会</a></li>
              <li><a href="https://www.ahghw.org/" target="_blank" rel="noopener noreferrer">安徽省总工会</a></li>
              <li><a href="https://www.scgh.org/" target="_blank" rel="noopener noreferrer">四川省总工会</a></li>
              <li><a href="http://www.nmgzgh.org.cn/" target="_blank" rel="noopener noreferrer">内蒙古自治区总工会</a></li>
              <li><a href="http://www.cfwu.org/" target="_blank" rel="noopener noreferrer">中国金融工会</a></li>
              <li><a href="https://www.3m.com.cn/" target="_blank" rel="noopener noreferrer">3m中国有限公司</a></li>
              <li><a href="http://www.cmpbook.com/" target="_blank" rel="noopener noreferrer">机械工业出版社</a></li>
            </div>
          </ul> -->
        </div>
      </div>
      <div class="deep_cont m_auto flex">
        <div class="code_cont">
          <p class="img">
            <img src="@/assets/pc/footer/code_img.png" alt="" />
          </p>
        </div>
        <div class="copy_cont">
          <div class="text_info">
            <p class="text1">扫码添加联系我们</p>
            <p class="text2">
              © 2023中工科创文化传媒（北京）有限公司 版权所有京ICP备<a
                href="https://beian.miit.gov.cn/"
                target="_blank"
                rel="noopener noreferrer"
              >
                16012224号-1
              </a>
            </p>
          </div>
        </div>
        <!-- <div class="right_cont flex">
          <p class="text">关注我们</p>
          <p class="img"><img src="@/assets/pc/footer/wx_icon.png" alt=""></p>
        </div> -->
      </div>
    </div>
    <!-- <div class="mask_box" v-if="maskState">
      <div class="aboutUs_box">
        <p class="close cursor" @click="maskState=false">×</p>
        <p class="img">
          <img src="@/assets/code_img.png" alt="">
        </p>
        <p class="text">扫码添加联系我们</p>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  data() {
    return {
      dataInfo: "",
      maskState: false,
    };
  },
  props: {
    deepLogoUrl: {
      type: String,
      default: "",
    },
    maskState1: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    maskState1(newValue, oldValue) {
      console.log(newValue, "oldValue", oldValue);
      this.maskState = newValue;
    },
  },
  mounted() {
    this.dataInfo = JSON.parse(localStorage.getItem("siteInfo"));
    console.log(this.maskState, "maskState");
  },
  methods: {
    aboutUsBtn() {
      this.maskState = true;
      this.$emit("state", "true");
    },
  },
};
</script>

<style scoped lang="scss">
a {
  // font-size: 28px;
  color: #c1c1c1;
}
.footer_box {
  min-height: 100vh;
  background: #000;
  background-size: 100% 100%;
  width: 100%;
  padding: 80px 28px 62px;
  position: relative;
  .left_about_us {
    p.logo {
      img {
        width: 250px;
        height: 103px;
      }
    }
    .phone_cont {
      margin-top: 50px;
      .phon_text {
        border-bottom: 1px solid #fff;
      }
      p.tips {
        width: 180px;
        padding-right: 24px;
        font-size: 32px;
        color: #ffffff;
        padding-left: 12px;
      }
      .phone_num {
        border-left: 1px solid #fff;
        padding-left: 22px;
        img {
        }
        span {
          margin-left: 14px;
          color: #03e5c4;
          font-size: 54px;
        }
      }
      .adr_text {
        p.tips {
        }
        p.text {
          padding-top: 15px;
          flex: 1;
          font-size: 28px;
          color: #ffffff;
          padding-left: 22px;
          border-left: 1px solid #fff;
        }
      }
    }
  }
  .ul_cont {
    ul {
      margin-top: 40px;
      p {
        color: #fff;
        font-size: 28px;
      }
      div {
        display: flex;
        flex-wrap: wrap;
        li {
          margin-top: 20px;
          margin-right: 40px;
          a {
            font-size: 28px;
            color: #c1c1c1;
          }
        }
      }
    }
  }

  .deep_cont {
    margin-top: 50px;
    .code_cont {
      img {
        width: 196px;
        height: 196px;
      }
    }
    .copy_cont {
      .text_info {
        margin-left: 36px;
        p {
          font-size: 28px;
          color: #fff;
        }
        p.text2 {
          color: #c1c1c1;
          margin-top: 26px;
        }
      }
    }
  }
}
.mask_box {
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
  z-index: 999;
  .aboutUs_box {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    width: 300px;
    height: 300px;
    border-radius: 10px;
    box-sizing: border-box;
    p.close {
      display: flex;
      justify-content: flex-end;
      font-size: 20px;
      padding: 0 10px;
    }
    p.img {
      width: 200px;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
    p.text {
      text-align: center;
      font-size: 15px;
      margin-top: 10px;
    }
  }
}
</style>
