<template>
  <div class="foot_main">
    <div class="footer_top"></div>
    <div class="footer_box">
      <div class="footer_cont w_1200 m_auto">
        <div class="left_about_us">
          <p class="logo"><img src="@/assets/pc/footer/logo.png" alt=""></p>
          <div class="phone_cont">
            <p class="tips">联系我们</p>
            <div class="phone_num flex">
              <img src="@/assets/pc/footer/phone.png" alt="">
              <span>400-0123-823</span>
            </div>
            <p class="adr_text">
              北京市东城区首东国际大厦B座4号楼17层
            </p>
            <div class="code_cont">
              <p class="img">
                <img src="@/assets/pc/footer/code_img.png" alt="">
              </p>
              <p class="text">官方公众号</p>
            </div>
          </div>
        </div>
        <div class="ul_cont">
          <ul>
            <p>推荐产品</p>
             <li><a href="http://open.kskstudy.com/" target="_blank" rel="noopener noreferrer">中工KSK开放平台</a></li>
             <li><a href="https://npx.kskstudy.com/" target="_blank" rel="noopener noreferrer">中工云课堂</a></li>
             <li><a href="https://zgyxk.kskstudy.com/index" target="_blank" rel="noopener noreferrer">中工云选课</a></li>
             <li><a href="https://npx.kskstudy.com/teacherList" target="_blank" rel="noopener noreferrer">大师来了</a></li>
             <li><a href="https://zgyxk.kskstudy.com/researchList" target="_blank" rel="noopener noreferrer">企业研学</a></li>
          </ul>
          <ul>
            <p>关于中工</p>
             <li><a href="https://www.kjcxchina.com/aboutUs" target="_blank" rel="noopener noreferrer">公司简介</a></li>
             <li @click="$router.push('/action')"><a href="javascript:;"  rel="noopener noreferrer">新闻中心</a></li>
             <!-- <li><a href="http://open.kskstudy.com/Join" target="_blank" rel="noopener noreferrer">人才招聘</a></li> -->
             <li @click="$router.push('/aboutUs')"><a href="javascript:;" rel="noopener noreferrer">人才招聘</a></li>
             <li><a href=" http://open.kskstudy.com/History" target="_blank" rel="noopener noreferrer">发展历程</a></li>
             <li><a href="javascript:;" rel="noopener noreferrer">社会责任</a></li>
          </ul>
          <ul>
            <p>服务支持</p>
             <li><a href="javascript:;"  rel="noopener noreferrer">政企服务</a></li>
             <li><a href="javascript:;"  rel="noopener noreferrer">合作伙伴</a></li>
             <!-- <li @click="aboutUsBtn()"><a href="javascript:;"  rel="noopener noreferrer">联系我们</a></li> -->
             <li @click="$router.push('/aboutUs')"><a href="javascript:;"  rel="noopener noreferrer">联系我们</a></li>

          </ul>
          <!-- <ul>
            <p>友情链接</p>
            <li><a href="http://www.mohrss.gov.cn/" target="_blank" rel="noopener noreferrer">中华人民共和国人力资源和社会保障部</a> </li>
            <li><a href="https://www.miit.gov.cn/" target="_blank" rel="noopener noreferrer">中华人民共和国工业和信息化部</a></li>
            <li><a href="https://www.acftu.org/" target="_blank" rel="noopener noreferrer">中华全国总工会</a></li>
            <li><a href="http://www.hbzgh.org.cn/" target="_blank" rel="noopener noreferrer">湖北省总工会</a></li>
            <li><a href="https://www.ahghw.org/" target="_blank" rel="noopener noreferrer">安徽省总工会</a></li>
            <li><a href="https://www.scgh.org/" target="_blank" rel="noopener noreferrer">四川省总工会</a></li>
            <li><a href="http://www.nmgzgh.org.cn/" target="_blank" rel="noopener noreferrer">内蒙古自治区总工会</a></li>
            <li><a href="http://www.cfwu.org/" target="_blank" rel="noopener noreferrer">中国金融工会</a></li>
            <li><a href="https://www.3m.com.cn/" target="_blank" rel="noopener noreferrer">3m中国有限公司</a></li>
            <li><a href="http://www.cmpbook.com/" target="_blank" rel="noopener noreferrer">机械工业出版社</a></li>
          </ul> -->
        </div>
        
      </div>
      <div class="deep_cont w_1200 m_auto flex">
        <div class="copy_cont">
          <div class="text_info">
            <p>Copyright © 2015-{{new Date().getFullYear()}} kjcxchina.com All Rights Reserved</p>
            <p>版权所有：中工科创    京ICP备<a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">
             16012224号-1</a> </p>
          </div>
        </div>
        <!-- <div class="right_cont flex">
          <p class="text">关注我们</p>
          <p class="img"><img src="@/assets/pc/footer/wx_icon.png" alt=""></p>
        </div> -->

      </div>
    </div>
    <div class="mask_box" v-if="maskState">
      <div class="aboutUs_box">
        <p class="close cursor" @click="maskState=false">×</p>
        <p class="img">
          <img src="@/assets/code_img.png" alt="">
        </p>
        <p class="text">扫码添加联系我们</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  data() {
    return {
      dataInfo: "",
      maskState:false
    };
  },
  props: {
    deepLogoUrl: {
      type: String,
      default: "",
    },
    maskState1:{
      type:Boolean,
      default:false,
    }
  },
  watch:{
    maskState1(newValue,oldValue){
      console.log(newValue,'oldValue',oldValue)
      this.maskState = newValue
    }
  },
  mounted() {
    this.dataInfo = JSON.parse(localStorage.getItem("siteInfo"));
    console.log(this.maskState,'maskState')
  },
  methods: {
    aboutUsBtn(){
      this.maskState=true
      this.$emit('state','true')
    }
  },
};
</script>

<style scoped lang="scss">
// .footer_top{
//   width: 100%;
//   height: 192px;
//   background: #F7FBFC;
// }
a{
  color: #C1C1C1;
}
.footer_box{
  background: #000;
  // background: url('@/assets/pc/index/footer_bgm.png') no-repeat;
  background-size: 100% 100%;
  width: 100%;
  padding: 95px 0 26px;
  position: relative;
  .footer_cont{
    width: 1200px;
    // margin: 0 auot;
    
    display: flex;
    .left_about_us{
      border-right: 1px solid #333;
      padding-right: 66px;
      padding-bottom: 35px;
      .phone_cont{
        margin-top: 60px;
        p.tips{
          font-size: 16px;
          color: #FFFFFF;
        }
        div.phone_num{
          margin-top: 19px;
          span{
            font-size: 20px;
            color: #03E5C4;
            margin-left: 7px;
          }
        }
        .adr_text{
          margin-top: 20px;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 22px;
        }
        .code_cont{
          margin-top: 40px;
          p.text{
            margin-top: 10px;
            font-size: 12px;
            color: #FFFFFF;
          }
        }

      }
    }
    .ul_cont{
      display: flex;
      width: 100%;
      // justify-content: space-between;
      margin-left: 65px;
      ul{
        margin-right: 120px;
        p{
          font-size: 18px;
          color: #FFFFFF;
          margin-bottom: 39px;
        }
        li{
          
          margin-bottom: 17px;
          font-size: 0;
          a{
            font-size: 16px;
          color: #C1C1C1;
          }
        }
      }
    }
  }
  .deep_cont{
    border-top: 1px solid #333;
    justify-content: space-between;
    padding-top: 16px;
    .text_info{
      p{
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #C1C1C1;
        margin-bottom: 13px;
      }
    }
    .right_cont{
      p.text{
        font-size: 18px;
        color: #C1C1C1;
        margin-right: 43px;
      }
    }
  }
}
.mask_box{
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0,0,0,.5);
  width: 100%;
  height: 100vh;
  z-index: 999;
  .aboutUs_box{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    background: #fff;
    width: 300px;
    height: 300px;
    border-radius: 10px;
    box-sizing: border-box;
    p.close{
      display: flex;
      justify-content: flex-end;
      font-size: 20px;
      padding:0 10px;
      
    }
    p.img{
      width: 200px;
      margin: 0 auto;
      img{
        width: 100%;
      }
    }
    p.text{
      text-align: center;
      font-size: 15px;
      margin-top: 10px;
    }
  }
}
</style>
