import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import PCHome from '@/views/pc/home.vue'
import MHome from '@/views/m/home.vue'

import { IsPC as IsPC } from '@/utils/util'

Vue.use(VueRouter)

// if(window.location.host == 'zzcl.kjcxchina.com'){
//   indexUrl = '../views/pc/index.vue'
// }
// if(window.location.host=='jt.zzcl.kjcxchina.com'){
//   indexUrl = '../views/pc/group_index.vue'
//   // @/views/pc/group_index.vue
// }

const routes_pc = [
  {
    path: '*',
    redirect: '/'
  },

  {
    path: '/',
    name: 'PCHome',
    component: PCHome,
    children: [
      //首页
      {
        path: '/',
        name: 'Index',
        component: () => import('@/views/pc/index.vue'),
        meta: {
          headerIsShow: true,
          footerIsShow: true,
        }
      },
      //产品服务
      {
        path: '/product',
        name: 'Product',
        component: () => import('@/views/pc/product.vue'),
      },
      // 产品详情
      {
        path: '/productDetail',
        name: 'productDetail',
        component: () => import('@/views/pc/productDetail.vue'),
      },
      // 解决方案
      {
        path: '/solution',
        name: 'solution',
        component: () => import('@/views/pc/solution.vue'),
      },
      // 关于我们
      {
        path: '/aboutUs',
        name: 'aboutUs',
        component: () => import('@/views/pc/aboutUs.vue'),
      },
      // 关于我们
      {
        path: '/newsDetail',
        name: 'newsDetail',
        component: () => import('@/views/pc/newsDetail.vue'),
      },
      {
        path: '/cooperate',
        name: 'cooperate',
        component: () => import('@/views/pc/cooperate.vue'),
      },
      // 公司动态
      {
        path: '/action',
        name: 'action',
        component: () => import('@/views/pc/action.vue'),
      },
      //详情
      // {
      //   path: '/project/detail',
      //   name: 'project/detail',
      //   component: () => import('@/views/pc/project/detail.vue'),
      // },
     
    ]
  }
]
const routes_m = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/pc',
    name: 'mHome',
    component: MHome,
    children: [
      //首页
      {
        path: '/index',
        name: 'Index',
        component: () => import('@/views/m/index.vue'),
        meta: {
          headerIsShow: true,
          footerIsShow: true,
        }
      },//产品服务
      {
        path: '/product',
        name: 'Product',
        component: () => import('@/views/m/product.vue'),
      },
      // 产品详情
      {
        path: '/productDetail',
        name: 'productDetail',
        component: () => import('@/views/m/productDetail.vue'),
      },
      // 解决方案
      {
        path: '/solution',
        name: 'solution',
        component: () => import('@/views/m/solution.vue'),
      },
      // 关于我们
      {
        path: '/aboutUs',
        name: 'aboutUs',
        component: () => import('@/views/m/aboutUs.vue'),
      },
      // 关于我们
      {
        path: '/newsDetail',
        name: 'newsDetail',
        component: () => import('@/views/m/newsDetail.vue'),
      },
      {
        path: '/cooperate',
        name: 'cooperate',
        component: () => import('@/views/m/cooperate.vue'),
      },
      // 公司动态
      {
        path: '/action',
        name: 'action',
        component: () => import('@/views/m/action.vue'),
      },
      //详情
      // {
      //   path: '/project/detail',
      //   name: 'project/detail',
      //   component: () => import('@/views/pc/project/detail.vue'),
      // },
    ]
  },
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: IsPC() ? routes_pc : routes_m
})

export default router
